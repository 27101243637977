import moment from 'moment';
import 'moment/locale/pt';
import {useCoursesMetadata} from '@queries/use-courses-metadata';

require('moment-timezone')

moment.locale('pt');

const timezone = 'Europe/Lisbon';

const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro',
    'Outubro', 'Novembro', 'Dezembro']

const getTimezonedDate = dateString => moment.utc(dateString).tz(timezone);

export const getCurrentMonth = () => moment.utc().month();

const getPathToCoursePage = (node) => {
  const {pathsToCourses} = useCoursesMetadata();
  const result = pathsToCourses.find(course => course.context.iteration_id === node.course_iter_id);
  return result ? result.path : '';
};

const isCourseOpenForSignUp = node => {
  return isSignUpOpen({startDate: node.applications_start_date, endDate: node.applications_end_date});
};

const isCourseClosedForSignUp = node => {
  return !isCourseOpenForSignUp(node);
};

const isSignUpOpen = ({ startDate, endDate }) => {
  const today = getTimezonedDate();
  const insideLowerLimit = today.isSameOrAfter(getTimezonedDate(startDate), 'minute');
  const insideUpperLimit = today.isSameOrBefore(getTimezonedDate(endDate), 'minute');

  return insideLowerLimit && insideUpperLimit;
};

const getEndDateLabel = value => {
  const timeSegment = getTimezonedDate(value).format('D [de] MMMM [de] YYYY');
  return `candidaturas até ${timeSegment}`;
};

const getImageNodeByName = (name, images) => {
  const edge = images.edges.find(edge => {
    const a = String(name).toLowerCase();
    const b = String(edge.node.name).toLowerCase();
    return a === b;
  });

  if (edge) {
    return edge.node;
  }

  return null;
};


export {
  getEndDateLabel,
  isSignUpOpen,
  getPathToCoursePage,
  isCourseOpenForSignUp,
  isCourseClosedForSignUp,
  getImageNodeByName,
  months
};
