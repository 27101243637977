import { useStaticQuery, graphql } from 'gatsby';

export const useCoursesMetadata = () => {
  const data = useStaticQuery(
    graphql`
      query SiteMetaData {
        courses: allRestApiCourseIterations(sort: {fields: applications_start_date, order: DESC}) {
          nodes {
            course {
              name
              id
            }
            iteration_name
            course_iter_id
            applications_end_date
            applications_start_date
            scholarity
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 60) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                  original {
                    src
                  }
                }
              }
            }
          }
        }
        # all pages that were created via actions.createPage()
        pathsToCourses: allSitePage(filter: {isCreatedByStatefulCreatePages: {eq: false}, path: {regex: "/cursos/"}}) {
          nodes {
            path
            context {
              iteration_id
            }
          }
        }
      }
    `
  );

  return {
    allCourses: data.courses.nodes,
    pathsToCourses: data.pathsToCourses.nodes
  };
};